import React from 'react';
import GlobalContextProvider from './src/context/GlobalContextProvider';

export const wrapRootElement = ({ element }) => {
      return <GlobalContextProvider>{element}</GlobalContextProvider>;
};

const HeadwayConfig = {
      selector: '#releaseNotes',
      account: 'xGr02y',
};

export const onInitialClientRender = () => {
      window.Headway.init(HeadwayConfig);
};

export const onRouteUpdate = () => {
      window.Headway.init(HeadwayConfig);
};
