import React from 'react';

export const GlobalStateContext = React.createContext();
export const GlobalDispatchContext = React.createContext();

const initialState = {
      user: null,
      projects: null,
      selectedProjectId: null,
      selectedProjectApps: null,
};

function reducer(state, action) {
      switch (action.type) {
            case 'SET_USER': {
                  return {
                        ...state,
                        user: action.user,
                  };
            }
            case 'SET_PROJECTS': {
                  return {
                        ...state,
                        projects: action.projects,
                  };
            }
            case 'SET_SELECTED_PROJECT': {
                  return {
                        ...state,
                        selectedProjectId: action.selectedProjectId,
                  };
            }
            case 'SET_SELECTED_PROJECT_APPS': {
                  return {
                        ...state,
                        selectedProjectApps: action.selectedProject,
                  };
            }
            default:
                  throw new Error('Bad Action Type');
      }
}

const GlobalContextProvider = ({ children }) => {
      const [state, dispatch] = React.useReducer(reducer, initialState);
      return (
            <GlobalStateContext.Provider value={state}>
                  <GlobalDispatchContext.Provider value={dispatch}>
                        {children}
                  </GlobalDispatchContext.Provider>
            </GlobalStateContext.Provider>
      );
};

export default GlobalContextProvider;
