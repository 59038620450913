// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-accounts-js": () => import("./../../../src/pages/accounts.js" /* webpackChunkName: "component---src-pages-accounts-js" */),
  "component---src-pages-addons-plans-js": () => import("./../../../src/pages/addons/plans.js" /* webpackChunkName: "component---src-pages-addons-plans-js" */),
  "component---src-pages-addonsmarketplace-js": () => import("./../../../src/pages/addonsmarketplace.js" /* webpackChunkName: "component---src-pages-addonsmarketplace-js" */),
  "component---src-pages-dashboard-js": () => import("./../../../src/pages/dashboard.js" /* webpackChunkName: "component---src-pages-dashboard-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-marketplace-js": () => import("./../../../src/pages/marketplace.js" /* webpackChunkName: "component---src-pages-marketplace-js" */),
  "component---src-pages-plans-js": () => import("./../../../src/pages/plans.js" /* webpackChunkName: "component---src-pages-plans-js" */),
  "component---src-pages-project-js": () => import("./../../../src/pages/project.js" /* webpackChunkName: "component---src-pages-project-js" */),
  "component---src-pages-settings-js": () => import("./../../../src/pages/settings.js" /* webpackChunkName: "component---src-pages-settings-js" */),
  "component---src-pages-setup-js": () => import("./../../../src/pages/setup.js" /* webpackChunkName: "component---src-pages-setup-js" */),
  "component---src-pages-signup-js": () => import("./../../../src/pages/signup.js" /* webpackChunkName: "component---src-pages-signup-js" */),
  "component---src-pages-support-js": () => import("./../../../src/pages/support.js" /* webpackChunkName: "component---src-pages-support-js" */),
  "component---src-pages-usage-js": () => import("./../../../src/pages/usage.js" /* webpackChunkName: "component---src-pages-usage-js" */)
}

